import * as React from "react"
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

interface Props {
    text?: string;
    link: string;
    className?: string;
    children?: React.ReactChild;
    anchor?: string;
    locale?: string;
}

const CustomLink: React.FC<Props> = ({ text, link, className, children, anchor, locale }) => {
    const isInternal = link && !link.includes('http');
    const isMailTo = link && link.includes('mailto');

    return anchor && !isMailTo
        ? <AnchorLink
            to={isInternal ? `/${locale}/${link}#${anchor}` : `${link}#${anchor}`}
            className={className}
        >
            {children ? children : text}
        </AnchorLink>
        : isMailTo
            ? <a href={link} target="_blank" className={className}>
                {children ? children : text}
            </a>
            : <Link to={isInternal ? `/${locale}/${link}` : link} className={className}>{children ? children : text}</Link>
}

export default CustomLink
