import * as React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { LocaleContext } from '../../context/LocaleContext';
import CustomLink from '../CustomLink';

type Language = 'cs-CZ' | 'en';

interface Props {
    className?: string
    link: {
        text: string
        link: string
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
            },
        },
        switch: {
            cursor: 'pointer',
            padding: '16px',
        },
        link: {
            textDecoration: 'none',
        },
    }),
);

const ContactUs: React.FC<Props> = ({className, link}) => {
    const classes = useStyles();
    const localeContext = useContext(LocaleContext);

    return <div className={[classes.root, className].join(" ")}>
        <div onClick={localeContext.toggleLocale} className={classes.switch}>
            {localeContext.locale === 'cs-CZ' ? <span><strong>CZ</strong> | ENG</span> : <span>CZ | <strong>ENG</strong></span>}
        </div>
        <CustomLink link={link.link} locale={localeContext.locale} className={classes.link}><Button variant="outlined" size="large" color="primary">{link.text}</Button></CustomLink>
    </div>
}

export default ContactUs
