import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline'
import Header from '../Header';
import { ReactChildren } from 'react';
import Footer, { FooterProps } from '../Footer';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import LocaleProvider from '../../context/LocaleContext';
import { NavigationProps } from '../Header/navigation';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: 'white',
        },
    }),
);

interface Props {
    children: ReactChildren | any | any[];
    footer: FooterProps;
    navigation: NavigationProps;
}

const Layout: React.FunctionComponent<Props> = ({ children, footer, navigation }) => {
    const classes = useStyles();

    return (
        <LocaleProvider>
            <div className={classes.root}>
                <CssBaseline />
                <Header navigation={navigation} />
                    {children}
                <Footer footer={footer} />
            </div>
        </LocaleProvider>
    );
}

export default Layout;
