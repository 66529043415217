import * as React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Navigation, { NavigationProps } from "./navigation"
import { StaticImage } from "gatsby-plugin-image"
import { Grid } from "@material-ui/core";
import ContactUs from "./contactUs";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { LocaleContext } from "../../context/LocaleContext";
import { Button } from '@material-ui/core';

interface Props {
    navigation: NavigationProps;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 30,
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                padding: "30px 10px",
            },
        },
        logo: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                justifyContent: 'left',
            },
        },
        alignCenter: {
            display: 'flex',
            alignItems: 'center',
        },
        navigation: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'center',
            },
        },
        navigationMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        contactUs: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                alignItems: 'center',
            },
        },
        hamburger: {
            width: '30px',
            height: '30px',
            marginRight: '25px',
            cursor: 'pointer',
        },
        hideDesktop: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        switch: {
            cursor: 'pointer',
            padding: '16px',
        },
        link: {
            textDecoration: 'none',
            margin: '0 auto'
        },
    }),
);

const Header: React.FC<Props> = ({ navigation }) => {
    const classes = useStyles();
    const localeContext = useContext(LocaleContext);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const ToggleNavigation = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }

    return <StaticQuery
        query={
            graphql`
            query UtilsQuery {
                strapiUtils {
                    logo {
                        localFile {
                            publicURL
                        }
                    }
                }
            }`
        }
        render={data => {
            return <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={6} md={3} className={classes.logo} justifyContent="flex-start">
                        <Link to={`/${localeContext.locale}`}>
                            <img src={data.strapiUtils.logo.localFile.publicURL} alt="Omnicrane" />
                        </Link>
                    </Grid>
                    <Grid item xs={6} className={classes.navigationMobile} alignItems="center" justifyContent="flex-end">
                        <div onClick={localeContext.toggleLocale} className={classes.switch}>
                            {localeContext.locale === 'cs-CZ' ? <span><strong>CZ</strong> | ENG</span> : <span>CZ | <strong>ENG</strong></span>}
                        </div>
                        <div onClick={() => ToggleNavigation()}>
                            <StaticImage src="../../images/hamburger_icon.png" alt="Menu" className={classes.hamburger} />
                        </div>
                    </Grid>
                    {isMobileMenuOpen && <React.Fragment>
                        <Grid item xs={12} className={classes.navigationMobile} justifyContent="center">
                            <Navigation navigation={navigation} />
                        </Grid>
                        {/* <Link to={`/${localeContext.locale}/${localeContext.locale === "en" ? "contact-us" : "kontakt"}#form`} className={classes.link}>
                            <Button variant="outlined" size="large" color="primary">{localeContext.locale === "en" ? "Contact" : "Kontakt"}</Button>
                        </Link> */}
                    </React.Fragment>}
                    <Grid item xs={12} md={6} className={classes.navigation} justifyContent="center">
                        <Navigation navigation={navigation} />
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.contactUs} justifyContent="flex-end">
                        {navigation && navigation.ContactUsButton && <ContactUs link={navigation.ContactUsButton} />}
                    </Grid>
                </Grid>
            </div>
        }} />
}

export default Header
