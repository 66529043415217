import * as React from "react"
import { Link } from "gatsby"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { StaticImage } from "gatsby-plugin-image"
import Section from "../Section";
import theme from "../../gatsby-theme-material-ui-top-layout/theme";
import { Divider, Grid, InputLabel, Typography } from "@material-ui/core";
import CustomLink from "../CustomLink";
import Headline from "../Headline";
import { Locale } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#fff',
            paddingTop: '45px',
            fontSize: 14,
            fontWeight: 'normal',
            lineHeight: 2.21,
            [theme.breakpoints.up('md')]: {
                padding: '70px 100px 50px 100px',
            },
        },
        link: {
            textDecoration: 'none',
            color: '#8690a0',
            "&:hover": {
                textDecoration: 'underline',
            }
        },
        linkBold: {
            textDecoration: 'none',
            color: 'white',
            fontWeight: 'bold',
            "&:hover": {
                textDecoration: 'underline',
            }
        },
        socialMediaLink: {
            textDecoration: 'none',
        },
        purple: {
            color: theme.palette.primary.main
        },
        contacts: {
            marginBottom: '20px',
            lineHeight: 1.1,
        },
        contactsTop: {
            marginTop: '20px',
            marginBottom: 0,
        },
        address: {
            margin: '20px 0',
            lineHeight: 1
        },
        divider: {
            background: "#8690a0",
            marginTop: '100px',
            height: '1px',
        },
        bottomText: {
            color: '#8690a0',
            padding: '20px',
            display: "flex",
            justifyContent: "center",
        },
        mobilePadding: {
            [theme.breakpoints.down('sm')]: {
                paddingTop: '50px!important',
            },
        },
        logo: {
            maxWidth: "100%",
        },
        businessCard: {
            wordBreak: "break-word",
        },
        bottomLinks: {
            display: "flex",
            justifyContent: "center",
        },
        bottomLink: {
            marginRight: "8px"
        },
        desktop: {
            display: "none",
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        mobile: {
            display: "block",
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    }),
);

export interface FooterProps {
    locale: Locale;
    columns: {
        FooterColumnItem: {
            text: string;
            link: string;
            anchor?: string;
        }[]
        Title: string;
    }[]
    bottomLinks: {
        id: string
        link: string;
        text: string;
        anchor?: string;
    }[]
    city: string;
    state: string;
    zipCode: string;
    email: string;
    id: string;
    identification: string;
    mobilePhone: string;
    street: string;
    socialMedia: {
        name: string;
        image: {
            localFile: {
                publicURL: string;
            }
        }
        link: string;
    }[]
    bottomText: string;
    bottomHeadlineSection: {
        inputButtonText: string;
        highlighted: string;
        inputLabel: string;
        titleAfter: string;
        titleBefore: string;
        mainLink?: {
            link: string
            text: string
        }
    }
}

type Props = {
    footer: FooterProps
  }

const Footer: React.FC<Props> = ({footer}) => {
    const classes = useStyles();
    const columnWidth = footer && footer.columns ? Math.floor(12/(footer.columns.length + 1)) : 3
    
    if(footer) {
       const { locale, street, city, zipCode, state, columns, socialMedia, bottomText, email, mobilePhone, identification, bottomLinks, bottomHeadlineSection } = footer;
    
        return <React.Fragment>
                    <Section color={theme.palette.secondary.main}>
                        <Headline
                                titleBefore={bottomHeadlineSection.titleBefore}
                                highlighted={bottomHeadlineSection.highlighted}
                                titleAfter={bottomHeadlineSection.titleAfter}
                                inputButtonText={bottomHeadlineSection.inputButtonText}
                                inputLabel={bottomHeadlineSection.inputLabel}
                                mainLink={bottomHeadlineSection.mainLink && bottomHeadlineSection.mainLink}
                            />
                    </Section>
                    <Section color={theme.palette.common.black}>
                    <div className={classes.root}>
                        <Grid container spacing={3}>
                            <Grid item xs={false} md={3} container spacing={3} className={classes.desktop}>
                                <Grid item xs={false} md={12} className={[classes.mobilePadding, classes.businessCard].join(" ")}>
                                    <StaticImage
                                        src="../../images/logo-inverted.svg"
                                        alt="Omnicrane"
                                    />
                                    <div className={classes.address}>
                                        {street}, {city}<br />
                                        {zipCode}, {state}
                                    </div>
                                    <div className={[classes.contacts, classes.contactsTop].join(" ")}>
                                        <p>
                                            <span className={classes.purple}>IČ: </span>{identification}
                                        </p>
                                    </div>
                                    <div className={classes.contacts}>
                                        <p>
                                            <span className={classes.purple}>M: </span>{mobilePhone}
                                        </p>
                                        <p>
                                            <span className={classes.purple}>E: </span>{email}
                                        </p>
                                    </div>
                                    <Grid container spacing={1} style={{ marginTop: '20px' }}>
                                        {socialMedia.map(item => <Grid item xs={1}>
                                            <Link key={item.name} to={item.link} className={classes.socialMediaLink}><img src={item.image.localFile.publicURL} /></Link>
                                        </Grid>)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={9} container justifyContent="space-between" spacing={3}>
                                {/* @ts-ignore */}
                                <Grid item xs={6} sm={columnWidth * 2} md={false} className={[classes.mobilePadding, classes.businessCard, classes.mobile].join(" ")}>
                                    <StaticImage
                                        src="../../images/logo-inverted.svg"
                                        alt="Omnicrane"
                                    />
                                    <div className={classes.address}>
                                        {street}, {city}<br />
                                        {zipCode}, {state}
                                    </div>
                                    <div className={[classes.contacts, classes.contactsTop].join(" ")}>
                                        <p>
                                            <span className={classes.purple}>IČ: </span>{identification}
                                        </p>
                                    </div>
                                    <div className={classes.contacts}>
                                        <p>
                                            <span className={classes.purple}>M: </span>{mobilePhone}
                                        </p>
                                        <p>
                                            <span className={classes.purple}>E: </span>{email}
                                        </p>
                                    </div>
                                    <Grid container spacing={1} style={{ marginTop: '20px' }}>
                                        {socialMedia.map(item => <Grid item xs={1}>
                                            <Link key={item.name} to={item.link} className={classes.socialMediaLink}><img src={item.image.localFile.publicURL} /></Link>
                                        </Grid>)}
                                    </Grid>
                                </Grid>

                                {/* @ts-ignore */}
                                {columns.map(column => <Grid item xs={6} sm={columnWidth * 2} md={columnWidth } justifyContent="center" className={classes.mobilePadding}>
                                    <Typography variant="h5">
                                        {column.Title}
                                    </Typography>
                                    {column.FooterColumnItem.map(item => <div>
                                        <CustomLink link={item.link} locale={locale} className={classes.link} text={item.text} anchor={item.anchor || undefined} />
                                    </div>)}
                                </Grid>)}
                            </Grid>
                        </Grid>
                        <Divider light className={classes.divider} />
                        <div className={classes.bottomText}>
                            {bottomText}
                        </div>

                        <div className={classes.bottomLinks}>
                            {bottomLinks.map(item => <CustomLink link={item.link} locale={locale} className={[classes.link, classes.bottomLink].join(" ")} text={item.text} anchor={item.anchor || undefined} />)}
                        </div>
                    </div>
                </Section>
            </React.Fragment> 
    }
    return <Section color={theme.palette.common.black}>
        No footer data
    </Section>
}

export default Footer
