import * as React from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";

interface Props {
    text: string;
    center?: boolean;
    alternative?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        badge: {
            color: theme.palette.primary.main,
            background: 'rgba(239,13,103, 0.1)',
            padding: '5px 15px',
            display: 'table',
            marginBottom: '10px'
        },
        badgeAlternative: {
            color: theme.palette.secondary.contrastText,
            background: theme.palette.secondary.main,            
        },
        center: {
            margin: '0 auto',
        }
    }),
);

const Badge: React.FC<Props> = ({ text, center, alternative }) => {
    const classes = useStyles();

    return <div className={[classes.badge, alternative && classes.badgeAlternative, center && classes.center].join(' ')}>
        <Typography variant="h4" >
            {text}
        </Typography>
    </div>
}

export default Badge

