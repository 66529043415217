import * as React from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from "@material-ui/core";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Badge from "../Badge";
import RequestDemo from '../RequestDemo';
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby";

interface Props {
    highlighted: string;
    titleBefore?: string | JSX.Element;
    titleAfter?: string | JSX.Element;
    text?: any;
    inputLabel?: string;
    inputButtonText?: string;
    rightImage?: any;
    badge?: string;
    mainLink?: {
        link: string
        text: string
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '40px 0',
            [theme.breakpoints.down('sm')]: {
                padding: '0 0 25px 0',
            },
        },
        highlightedText: {
            color: theme.palette.primary.main,
            position: 'relative',
        },
        underlineImage: {
            position: 'absolute',
            bottom: '-14px',
            left: 0,
        },
        text: {
            paddingTop: 20,
        },
        input: {
            maxWidth: 456,
            paddingTop: 20,
            margin: '0 auto',
        },
        inputWithImage: {
            maxWidth: 456,
            paddingTop: 20,
        },
        markdown: {
            '& a': {
                color: '#ea1674',
                fontWeight: 'bold',
                textDecoration: 'underline',
            }
        },
        imageWrapper: {
            height: '350px',
            overflow: 'hidden',
            [theme.breakpoints.up('md')]: {
                height: '705px',
            },
        },
        image: {
            maxWidth: '100%',
        },
        button: {
          height: 56,
        },
        buttonWrapper: {
            display: "flex",
            justifyContent: "center",
            padding: "25px",
            textDecoration: "none",
        },
    }),
);

const Headline: React.FC<Props> = ({ highlighted, titleBefore, titleAfter, text, inputLabel, inputButtonText, rightImage, badge, mainLink }) => {
    const classes = useStyles();
    const image = rightImage && rightImage.localFile.childImageSharp.gatsbyImageData;

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                {!image && <Grid item xs={12} sm={3} />}
                <Grid item xs={12} sm={6}>
                    {badge && <Badge text={badge} center />}
                    <Typography variant="h1" gutterBottom align={!image ? "center" : "left"}>
                        {titleBefore && titleBefore}
                        {" "}
                        {highlighted && <span className={classes.highlightedText}>
                            {highlighted}
                        </span>}
                        {" "}
                        {titleAfter && titleAfter}
                    </Typography>
                    {text &&
                        <Typography variant="body1" gutterBottom align={!image ? "center" : "left"} className={classes.text}>
                            <ReactMarkdown linkTarget="_blank" className={classes.markdown}>
                                {text}
                            </ReactMarkdown>
                        </Typography>
                    }
                    {/* {inputLabel && inputButtonText &&
                        <RequestDemo inputLabel={inputLabel} inputButtonText={inputButtonText} />
                    } */}
                    {/* {inputButtonText && <div className={classes.buttonWrapper}>
                        <Button variant="contained" color="primary" className={classes.button}>{inputButtonText}</Button>
                    </div>} */}
                    {mainLink && <Link to={mainLink.link} className={classes.buttonWrapper}>
                        <Button variant="contained" color="primary" className={classes.button}>{mainLink.text}</Button>
                    </Link>}
                </Grid>
                {image && <Grid item xs={12} sm={6} className={classes.imageWrapper}><GatsbyImage image={image} alt="omni" className={classes.image} /></Grid>}
            </Grid>

        </div>
    )
}

export default Headline
