import { Container } from "@material-ui/core";
import * as React from "react"

interface Props {
    children: React.ReactChildren | any | any[];
    color: string;
    className?: string;
}

const Section: React.FunctionComponent<Props> = ({ children, color, className }) => {
    return (
        <div className={className} style={{ background: color }}>
            <Container maxWidth="lg">
                {children}
            </Container>
        </div>
    );
}

export default Section;
