import * as React from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Link } from "gatsby"
import NavigationDropdown from "./navigationDropdown";
import { LocaleContext } from "../../context/LocaleContext";

export interface NavigationProps {
    locale
    navigationItem: {
        title: string;
        customLink: string;
        NavigationSubItems: {
            title: string
            sub_page: {
                slug: string
                pageTitle: string
            }
        }[]
    }[]
    ContactUsButton: {
        link: string
        text: string
    }
}
interface Props {
    navigation: NavigationProps
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
        },
        navItem: {
            textDecoration: 'none',
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 5.06,
            color: "#202338",
            cursor: "pointer",
            [theme.breakpoints.up('md')]: {
                paddingRight: 30,
            },
        },
    }),
);

const Navigation: React.FC<Props> = ({ navigation }) => {
    const classes = useStyles();
    const localeContext = React.useContext(LocaleContext);

    return <div className={classes.root}>

        {navigation && navigation.navigationItem.map((item, i) => {
            const hasSubitems = item.NavigationSubItems && item.NavigationSubItems.length !== 0;

            if (hasSubitems) {
                return <NavigationDropdown subItems={item.NavigationSubItems} label={item.title} key={i} locale={navigation.locale} />
            }

            return <Link
                key={item.customLink}
                to={`/${localeContext.locale}/${item.customLink}`}
                // anchor={}
                className={classes.navItem}
            >
                {item.title}
            </Link>
        })
        }
    </div>
}

export default Navigation;
