import { createStyles, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import * as React from 'react';
import { FC } from 'react';

interface SubPage {
    slug: string;
    pageTitle: string;
}

interface SubItem {
    title: string;
    sub_page: SubPage;
    slug: string;
}

interface Props {
    label: string;
    locale: string;
    subItems: SubItem[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        navItem: {
            textDecoration: 'none',
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 5.06,
            color: "#202338",
            cursor: "pointer",
            [theme.breakpoints.up('md')]: {
                paddingRight: 30,
            },
        },
        subNav: {
            zIndex: 1,
            marginTop: '60px',
        },
        subNavItem: {
            textDecoration: 'none',
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 600,
            color: "#202338",
            cursor: "pointer",
        },
        subNavLink: {
            '&:hover': {
                textDecoration: 'none',
            }
        },
        arrow: {
            color: theme.palette.primary.main,
            fontSize: 16,
            marginLeft: '6px',
            transform: 'rotate(90deg)',
            display: 'inline-block',
        },
        popover: {
            background: 'black',
            opacity: 0.2,
        },
    }),
);

function extractSlug(si: SubItem): string {
    if (si.slug) {
        return si.slug
    }

    if (si.sub_page && si.sub_page.slug) {
        return si.sub_page.slug
    }

    return "#"
}

const NavigationDropdown: FC<Props> = ({ label, subItems, locale }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                // @ts-ignore
                onClick={(e) => handleClick(e)}
                className={classes.navItem}
            >
                {label}
                {open ? <div className={classes.arrow}>{'<'}</div> : <div className={classes.arrow}>{'>'}</div>}
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className={classes.subNav}
                variant="selectedMenu"
            // PopoverClasses={classes.popover}
            >
                {
                    subItems.map(item => {
                        return <Link
                            key={extractSlug(item)}
                            to={`/${locale}/${extractSlug(item)}`}
                            className={classes.subNavLink}
                        >
                            <MenuItem onClick={handleClose} className={classes.subNavItem}>
                                {item.title}
                            </MenuItem>
                        </Link>
                    })
                }
            </Menu>
        </div>
    );
}

export default NavigationDropdown