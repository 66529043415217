import { navigate } from "gatsby";
import React, { useEffect, useState } from "react"
import { useLocation } from '@reach/router';

type Locale = 'cs-CZ' | 'en';

const defaultState = {
  locale: 'en',
  toggleLocale: () => {},
  setLocale: (locale: Locale) => {},
}

export const LocaleContext = React.createContext(defaultState)

const LocaleProvider: React.FC<{}> = ({children}) => {
    const { pathname } = useLocation();
    const [locale, setLocale] = useState<Locale>('en')
    
    useEffect(() => {
      if(pathname.includes('cs-CZ')) {
        setLocale('cs-CZ');
        localStorage.setItem('language', 'cs-CZ');
      } else {
        setLocale('en');
        localStorage.setItem('language', 'en');
      }
    }, [])

    const toggleLocale = () => {
        const aboutToBeSet: Locale = locale === 'cs-CZ' ? 'en' : 'cs-CZ';
        localStorage.setItem('language', aboutToBeSet)
        setLocale(aboutToBeSet);
        navigate(`/${aboutToBeSet}`)
    }

    const setLanguage = (locale: Locale) => {
        localStorage.setItem('language', locale)
        setLocale(locale)
    }

    return (
      <LocaleContext.Provider
        value={{
          locale,
          toggleLocale: toggleLocale,
          setLocale: setLanguage,
        }}
      >
        {children}
      </LocaleContext.Provider>
    )
}
export default LocaleProvider